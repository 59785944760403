#container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  min-height: 100%;
  min-width: 320px;
  overflow-x: hidden;

  body[data-state='loading'] & {
    display: none;
  }
};

#container:before {
  position: absolute;
  top: 20%;
  left: 0;
  content: " ";
  width: 100%;
  height: 80%;
  background: -moz-linear-gradient(top, $background-color 0%, #FFF 25%);
  background: -webkit-linear-gradient(top, $background-color 0%, #FFF 25%);
  background: linear-gradient(to bottom, $background-color 0%, #FFF 25%);
}