.nav-list {
  position: absolute;
  width: 100%;
  min-width: 320px;
  height: $nav-height;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border-left: $margin / 2 solid transparent;
  border-right: $margin / 2 solid transparent;
  /* */
  @media (max-width: $break-small) {
    height: $nav-height-small;
    border-left: $margin-small / 2 solid transparent;
    border-right: $margin-small / 2 solid transparent;
  }
  /* */
  transition: opacity 0.35s cubic-bezier($easeInCubic);
  transition-delay: 0.2s;
  /* */
  body[data-nav-min='true'] & {
    opacity: 0;
    transition-delay: 0s;
  }

  body[data-state='loading'] & {
    opacity: 0;
    transition-delay: 0s;
  }

  .list {
    position: absolute;
    bottom: 0;
    left: 25%;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      display: inline-block;
      height: $nav-height-small/1.5;
      width: auto;
      left: auto;
      right: 0;
      box-sizing: border-box;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }

    .production:hover {
      h2 {
        color: $production-color !important;
      }

      .hl:after,
      .hr:after {
        background-color: $production-color !important;
      }
    }

    .production {
      .nav-button:before {
        background-color: #a35229;
      }

      .knob {
        background-image: url("svg/knob-production.svg");
      }

      .knob:after {
        background-color: $production-color;
      }

      body[data-state='production'] & {
        h2 {
          color: $production-color !important;
        }

        .hl:after,
        .hr:after {
          background-color: $production-color !important;
        }
        @include navigationSelected();
      }
    }

    .studio:hover {
      h2 {
        color: $studio-color !important;
      }

      .hl:after,
      .hr:after {
        background-color: $studio-color !important;
      }
    }

    .studio {
      .nav-button:before {
        background-color: #861c11;
      }

      .knob {
        background-image: url("svg/knob-studio.svg");
      }

      .knob:after {
        background-color: $studio-color;
      }

      body[data-state='studio'] & {
        h2 {
          color: $studio-color !important;
        }

        .hl:after,
        .hr:after {
          background-color: $studio-color !important;
        }
        @include navigationSelected();
      }
    }

    .record:hover {
      h2 {
        color: $record-color !important;
      }

      .hl:after,
      .hr:after {
        background-color: $record-color !important;
      }
    }

    .record {
      nav-button:before {
        background-color: #455225;
      }

      .knob {
        background-image: url("svg/knob-record.svg");
      }

      .knob:after {
        background-color: $record-color;
      }

      body[data-state='record'] & {
        h2 {
          color: $record-color !important;
        }

        .hl:after,
        .hr:after {
          background-color: $record-color !important;
        }
        @include navigationSelected();
      }
    }

    .item {
      position: relative;
      cursor: pointer;
      flex-grow: 1;
      width: 33.3%;
      color: #999;
      @media (max-width: $break-small) {
        width: auto;
        float: left;

        .header {
          display: inline-block;
          flex-wrap: nowrap;
          text-align: center;

          h2 {
            width: 100%;
          }
        }

        .hl,
        .hr {
          display: none;
          flex-grow: 0;
        }
      }
    }

    .nav-button:before {
      content: " ";
      position: absolute;
      width: 50px;
      height: 16px;
      margin-left: -25px;
      margin-top: -6px;
      top: 20%;
      left: 50%;
      border-radius: 16px;
      transition: 0.3s;
    }

    .nav-button {
      position: relative;
      width: 8px;
      height: 60px;
      left: 50%;
      margin-left: -4px;
      margin-bottom: $margin;
      background-color: #333;
      border-radius: 8px;
      @media (max-width: $break-small) {
        display: none;
      }

      .knob:after {
        content: " ";
        position: absolute;
        width: 60px;
        height: 16px;
        margin-left: 20px;
        margin-top: -6px;
        border-radius: 16px;
        transition: 0.3s;
      }

      .knob {
        position: absolute;
        top: 20%;
        left: 50%;
        width: 100px;
        height: 2px;
        margin-left: -50px;
        margin-top: 0;
        background-size: 100%;
        transition: 0.3s;
      }
    }
  }
}