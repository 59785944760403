.header {
  position: relative;
  width: 100%;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  // outline: 1px green solid;
  .hl:after,
  .hr:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: $line-thick;
    top: 50%;
    margin-top: -$line-thick;
    background-color: $line-color;
    transition: background-color 0.3s;
  }

  .hl,
  .hr {
    position: relative;
    flex-grow: 1;
  }

  h1 {
    font-family: 'BebasNeueBold';
    letter-spacing: 1px;
    font-size: 34px;
    text-transform: uppercase;
    width: auto;
    text-align: center;
    padding-top: 4px;
    padding-left: $margin / 2;
    padding-right: $margin / 2;
    user-select: none;
    color: $line-color;
    transition: color 0.3s;
    @media (max-width: $break-small) {
      font-size: 20px;
      padding-left: $margin-small / 2;
      padding-right: $margin-small / 2;
    }
  }

  h2 {
    // outline: 1px red solid;
    font-family: 'BebasNeueBold';
    letter-spacing: 1px;
    font-size: 22px;
    margin: 0;
    text-transform: uppercase;
    width: auto;
    text-align: center;
    padding-top: 4px;
    padding-left: $margin-small;
    padding-right: $margin-small;
    user-select: none;
    color: $line-color;
    transition: color 0.3s;
    @media (max-width: $break-small) {
      font-size: 18px;
      padding-left: $margin-small / 2;
      padding-right: $margin-small / 2;
    }
  }
}