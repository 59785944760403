.quote {
  position: relative;
  width: 100%;
  margin-top: $margin;
  margin-bottom: $margin;
  display: flex;
  flex-wrap: wrap;
  float: left;
  user-select: none;
  @media (max-width: $break-small) {
    margin-top: $margin-small / 2;
    margin-bottom: $margin-small * 2;
  }

  .ql:before {
    content: " ";
    width: $margin;
    height: $line-thick;
    background-color: $line-color;
    position: absolute;
    top: 0;
    right: -$margin/2;
    @media (max-width: $break-small) {
      width: $margin-small;
      right: -$margin-small/2;
    }
  }

  .ql:after {
    content: " ";
    width: $margin;
    height: $line-thick;
    background-color: $line-color;
    position: absolute;
    top: 0;
    left: -$margin/2;
    @media (max-width: $break-small) {
      width: $margin-small;
      left: -$margin-small/2;
    }
  }

  .ql {
    position: relative;
    flex-grow: 1;
    top: 50%;
    width: 25%;
    height: $line-thick;
    background-color: $line-color;
    @media (max-width: $break-small) {
      width: 0;
    }
  }

  .qr:before {
    content: " ";
    width: $margin;
    height: $line-thick;
    background-color: $line-color;
    position: absolute;
    top: 0;
    right: -$margin/2;
    @media (max-width: $break-small) {
      width: $margin-small;
      right: -$margin-small/2;
    }
  }

  .qr:after {
    content: " ";
    width: $margin;
    height: $line-thick;
    background-color: $line-color;
    position: absolute;
    top: 0;
    left: -$margin/2;
    @media (max-width: $break-small) {
      width: $margin-small;
      left: -$margin-small/2;
    }
  }

  .qr {
    position: relative;
    flex-grow: 1;
    top: 50%;
    width: 25%;
    height: $line-thick;
    background-color: $line-color;
    @media (max-width: $break-small) {
      width: 0;
    }
  }

  .copy-container {
    flex-grow: 1;
    position: relative;
    width: 50%;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      width: 100%;
      left: 0;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }
  }

  .copy {
    position: relative;
    font-family: 'BebasNeueBook';
    letter-spacing: 2px;
    font-size: 30px;
    line-height: 32px;
    font-style: italic;
    width: 100%;
    text-align: center;
    padding: $margin * 2;
    box-sizing: border-box;
    color: $line-color;
    border: $line-thick $line-color solid;
    @media (max-width: $break-medium) {
      font-size: 26px;
      line-height: 28px;
    }
    @media (max-width: $break-small) {
      font-size: 20px;
      line-height: 24px;
      width: 100%;
      padding: $margin-small * 2;
    }
  }
}