#production {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin-top: $nav-height + $margin;
  box-sizing: border-box;
  border-left: $margin / 2 solid transparent;
  border-right: $margin / 2 solid transparent;
  @media (max-width: $break-medium) {
    margin-top: $nav-height + $margin *2;
  }
  @media (max-width: $break-small) {
    margin-top: $nav-height-small + $margin-small;
    border-left: $margin-small / 2 solid transparent;
    border-right: $margin-small / 2 solid transparent;
  }

  body[data-state='production'] & {
    @include displayPage();
  }

  a,
  a:active,
  a:link,
  a:visited {
    color: $production-color;
    border: 2px $production-color solid;
  }

  a:hover {
    background-color: $production-color;
    color: $background-color;
  }

  .article {
    margin-bottom: $margin*2;
    @media (max-width: $break-small) {
      margin-bottom: $margin-small*2;
    }
  }

  .quote {
    margin-bottom: $margin *3;
  }

  .contact {
    margin-bottom: $margin*2;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      margin-bottom: $margin-small*2;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }

    .ac:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: $line-color;
      opacity: 0.5;
    }

    .ac {
      padding-top: $margin;
      text-align: center !important;
    }
  }
}