body {
  margin: 0;
  padding: 0;
  min-width: 320px;
  width: 100%;
  background-color: $background-color;
  color: #333;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 13px;
  line-height: 19px;
  @media (max-width: $break-small) {
    font-size: 12px;
    line-height: 16px;
  }
};

body[data-display-video='true'] {
  background-color: #000;
}

::-webkit-scrollbar {
  display: none;
}

#isSmall {
  position: absolute;
  display: none !important;
  @media (max-width: $break-small) {
    display: inline-block !important;
  }
}

.desktop-only {
  display: inline-block !important;
  @media (max-width: $break-small) {
    display: none !important;
  }
}

.medium {
  display: none !important;
  @media (max-width: $break-medium) {
    display: inline-block !important;
    width: 100%;
  }
}

.mobile-only {
  display: none !important;
  @media (max-width: $break-small) {
    display: inline-block !important;
  }
}

a,
a:active,
a:link,
a:visited {
  font-weight: bold;
  color: $contact-color;
  text-decoration: none;
  border: 2px $contact-color solid;
  padding: 6px 10px;
  display: inline-block;
  transition: 0.2s;
}

a:hover {
  background-color: $contact-color;
  color: $background-color;
  text-decoration: none;
}

.page {
  position: fixed;
  opacity: 0;
  transform: scale(0.86);
  visibility: hidden;
  transition: 0.5s;
  @media (max-width: $break-small) {
    transform: scale(1);
    transition: 0.35s;
  }
}