#footer {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: $margin;
  background-color: #FFF;

  body[data-state='contact'] & {
    display: none;
  }

  body[data-state='studio'] & {
    .gallery {
      .left,
      .right {
        background-image: none;
        background-color: #000;
      }

      .center {
        background-image: url("images/gustav.jpg");
      }
    }
  }

  body[data-state='loading'] & {
    display: none;
  }

  .gallery {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;

    .left {
      flex-grow: 1;
      height: 100%;
      width: 37.5%;
      background-image: url("images/Gustav-Studio-11.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (max-width: $break-small) {
        display: none;
      }
      @media (max-width: $break-medium) {
        width: 25%;
      }
    }

    .center:hover {
      .background {
        opacity: 0.6;
      }

      .header {
        transition-delay: 0.3s;

        h2 {
          color: $contact-color;
        }

        .hl:after,
        .hr:after {
          background-color: $contact-color;
        }
      }
    }

    .center {
      cursor: pointer;
      flex-grow: 1;
      height: 100%;
      width: 25%;
      background-image: url("images/Gustav-Studio-4.jpg");
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: cover;
      background-color: #333;
      @media (max-width: $break-medium) {
        width: 50%;
      }

      img {
        width: 100%;
        opacity: 0;
        position: relative;
      }

      .background {
        position: absolute;
        width: 25%;
        height: 100%;
        background: -moz-linear-gradient(top, rgba(51, 120,231, 0) 0%, $contact-color 80%);
        background: -webkit-linear-gradient(top, rgba(51, 120,231,0) 0%, $contact-color 80%);
        background: linear-gradient(to bottom, rgba(51, 120,231,0) 0%, $contact-color 80%);
        opacity: 0;
        transition: opacity 0.3s;
        @media (max-width: $break-medium) {
          width: 50%;
        }
        @media (max-width: $break-small) {
          width: 100%;
        }
      }

      .header {
        position: absolute;
        top: 100%;
        padding-bottom: $margin / 2;
        padding-top: $margin / 2;
        width: 25%;
        left: 37.5%;
        box-sizing: border-box;
        transition: all 0.3s;
        transition-delay: 0s;
        @media (max-width: $break-medium) {
          width: 50%;
          left: 25%;
        }
        @media (max-width: $break-small) {
          box-sizing: border-box;
          border-right: $margin-small solid transparent;
          border-left: $margin-small solid transparent;
          padding-bottom: $margin-small;
          padding-top: $margin-small;
          width: 100%;
          left: 0;
        }
      }
    }

    .right {
      flex-grow: 1;
      height: 100%;
      width: 37.5%;
      background-image: url("images/Gustav-Studio-22.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @media (max-width: $break-small) {
        display: none;
      }
      @media (max-width: $break-medium) {
        width: 25%;
      }
    }
  }

  .signature-container {
    background-color: $background-color;

    .signature {
      position: relative;
      top: 0;
      left: 50%;
      width: 25%;
      text-align: center;
      transform: translate(-50%);
      padding-top: $margin * 3;
      padding-bottom: $margin * 4;
      @media (max-width: $break-medium) {
        width: 50%;
        left: 25%;
        transform: translate(0%);
      }
      @media (max-width: $break-small) {
        padding-top: $margin-small * 6;
        padding-bottom: $margin-small * 4;
      }

      img {
        margin-left: 20px;
        padding-bottom: $margin;
      }

      b {
        display: inline-block;
        font-family: 'BebasNeueBold';
        font-size: 16px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding-bottom: $margin / 2;
      }
    }
  }
}