
#orientation {
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: #000;
  color:#FFF;
  z-index: 50;
  opacity:1;
  text-transform: uppercase;
  transition: opacity $transition-speed2 cubic-bezier($easeInCubic);

  body[data-orientation='portrait'] & {
    width: 0%;
    height: 0%;
    opacity:0;
    transition: opacity 0s cubic-bezier($easeInCubic);
    visibility: hidden;
  }

  .message {
    font-family: DINWeb-CondBold;
    font-size: 20px;
    text-align: center;
    display: block;
    position: relative;
    top:50%;
    left:50%;
    transform : translateX(-50%) translateY(-50%);
  }

};
