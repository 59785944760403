#video {
  position: fixed;
  left: 100%;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-image: url("images/bg.png");
  bottom: 0;
  z-index: 100;
  transition: all 0.3s;

  body[data-display-video='true'] & {
    left: 0;
  }

  iframe {
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-left: $margin solid transparent;
    border-right: $margin solid transparent;
    border-top: $nav-height solid transparent;
    border-bottom: $nav-height solid transparent;
    @media (max-width: $break-small) {
      border-left: $margin-small solid transparent;
      border-right: $margin-small solid transparent;
    }
  }

  .info {
    position: absolute;
    display: inline-block;
    width: 100%;
    left: 0;
    bottom: $margin * 4;
    color: #999;
    text-align: center;
    box-sizing: border-box;
    border-left: $margin solid transparent;
    border-right: $margin solid transparent;
    @media (max-width: $break-small) {
      width: 100%;
      left: 0;
      bottom: $margin * 3;
      border-left: $margin-small solid transparent;
      border-right: $margin-small solid transparent;
    }

    .partition {
      display: inline-block;
      width: 10px;
    }
  }

  img {
    position: absolute;
    left: 50%;
    top: $margin;
    margin-left: -15px;
  }

  .header:hover {
    h2 {
      color: #FFF;
    }

    .hl:after,
    .hr:after {
      background-color: #FFF;
    }
  }

  .header {
    cursor: pointer;
    position: absolute;
    bottom: $margin;
    width: 100%;
    box-sizing: border-box;
    border-left: $margin solid transparent;
    border-right: $margin solid transparent;
    @media (max-width: $break-small) {
      border-left: $margin-small solid transparent;
      border-right: $margin-small solid transparent;
    }

    h2 {
      color: #999;
    }

    .hl:after,
    .hr:after {
      background-color: #999;
    }
  }
}