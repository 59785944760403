.image:hover {
  h1 {
    color: $production-color;
  }

  .hl:after,
  .hr:after {
    background-color: $production-color;
  }

  .info {
    padding-bottom: $margin;
    opacity: 1;
    transition-delay: 0.2s;
    @media (max-width: $break-small) {
      padding-bottom: $margin-small;
    }
  }

  .holder:before {
    opacity: 0.8 !important;
  }
}

.case-center {
  position: relative;
  width: 100%;
  margin-bottom: $margin;
  color: $background-color;
  @media (max-width: $break-small) {
    margin-bottom: $margin-small;
  }

  .info {
    position: absolute;
    text-align: left;
    left: 0;
    bottom: 64px;
    padding-left: $margin;
    padding-bottom: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0s;
    @media (max-width: $break-small) {
      padding-left: $margin-small/2 + $margin-small;
    }
  }

  .header {
    width: 100%;
    left: 0;
    @media (max-width: $break-small) {
      width: 100%;
      left: 0;
    }
  }

  .image {
    position: relative;
    width: 50%;
    left: 25%;
    cursor: pointer;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
      width: 100%;
      left: 0;
    }

    .holder:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(255,255,224,0) 0%, $production-color 50%);
      background: -webkit-linear-gradient(top, rgba(255,255,224,0) 0%, $production-color 50%);
      background: linear-gradient(to bottom, rgba(255,255,224,0) 0%, $production-color 50%);
      opacity: 0;
      transition: opacity 0.6s;
    }

    .holder {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #333;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px #CCC solid;

      img {
        width: 100%;
        opacity: 0;
      }
    }
  }
}