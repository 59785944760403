#navigation {
  position: fixed;
  top: -$nav-height;
  left: 0;
  width: 100%;
  opacity: 0;
  height: $nav-height-small;
  background-color: #333;
  transition: all 0.5s;
  overflow: hidden;

  body[data-nav-min='true'][data-display-video='false'] & {
    top: 0;
    opacity: 1;
  }

  .logo {
    position: relative;
    top: $margin-small;
    width: 25%;
    height: $nav-height-small - $margin-small - $margin-small;
    background-image: url("svg/EATMEATER_iso_white.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    box-sizing: border-box;
    border-top: 0 solid transparent;
    border-left: $margin solid transparent;
    border-right: $margin / 4 solid transparent;
    @media (max-width: $break-small) {
      background-position: center left;
      border-left: $margin-small solid transparent;
      border-right: $margin-small / 4 solid transparent;
    }
  }

  .nav-list {
    transition: none;
    opacity: 1;
    height: $nav-height-small / 1.5;

    h2 {
      color: $background-color;
      font-size: 18px;
    }

    .hl:after,
    .hr:after {
      background-color: $background-color;
    }

    .list {
      top: 50%;
      height: $nav-height-small;
    }
  }
}