#logo {
  position: relative;
  width: 25%;
  height: $nav-height;
  background-image: url("svg/EATMEATER_logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  box-sizing: border-box;
  border-top: $margin solid transparent;
  border-left: $margin solid transparent;
  border-right: $margin / 4 solid transparent;
  pointer-events: none;
  @media (max-width: $break-medium) {
    width: 25%;
  }
  @media (max-width: $break-small) {
    width: 50%;
    min-width: 320px;
    top: $margin-small;
    background-position: center left;
    background-image: url("svg/EATMEATER_iso.svg");
    height: $nav-height-small - $margin-small - $margin-small;
    border-top: 0 solid transparent;
    border-left: $margin-small solid transparent;
    border-right: $margin-small / 4 solid transparent;
  }

  body[data-state='loading'] & {
    top: 50%;
    left: 50%;
    margin-left: 8px;
    background-position: center;
    transform: translate(-50%, -50%);
    background-image: url("svg/EATMEATER_iso.svg");
    @media (max-width: $break-small) {
      margin-left: 5px;
    }
    animation: pulse 0.25s cubic-bezier($easeInOutQuart) infinite;
  }
}