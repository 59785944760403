#guide {
  position: fixed;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 100;
  min-width: 320px;
  pointer-events: none;
  z-index: 200;
  top: 0;
  left: 0;

  body[data-debug='true'] & {
    display: block;
  }

  .b,
  .t {
    position: absolute;
    width: 100%;
    height: 1px;
    background: $guide-color;
  }

  .l,
  .r {
    position: absolute;
    width: 1px;
    height: 100%;
    background: $guide-color;
  }

  .l,
  .t {
    top: 0;
    left: 0;
  }

  .b {
    bottom: 0;
    left: 0;
  }

  .r {
    top: 0;
    right: 0;
  }

  .border {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 22px #363636 solid;
    overflow: hidden;
    box-sizing: border-box;
    border-top: $margin solid transparent;
    border-bottom: $margin solid transparent;
    border-left: $margin solid transparent;
    border-right: $margin solid transparent;
    z-index: 100;
    @media (max-width: $break-small) {
      border-top: $margin-small solid transparent;
      border-bottom: $margin-small solid transparent;
      border-left: $margin-small solid transparent;
      border-right: $margin-small solid transparent;
    }
  }

  .center-v {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1px;
    height: 100%;
    background: $guide-color;
    opacity: 0.5;
  }

  .row-top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $nav-height;
    outline: 1px $guide-color solid;
    @media (max-width: $break-small) {
      height: $nav-height-small;
    }

    body[data-nav-min='true'][data-display-video='false'] & {
      height: $nav-height-small;
    }
  }

  .col-mid {
    position: absolute;
    top: 0;
    left: 50%;
    width: $margin;
    margin-left: -$margin / 2;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-small) {
      width: $margin-small;
      margin-left: -$margin-small / 2;
    }
  }

  .col-left-1 {
    position: absolute;
    top: 0;
    left: 25%;
    width: $margin;
    margin-left: -$margin/4;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-small) {
      width: $margin-small;
      margin-left: -$margin-small/4;
    }
  }

  .col-right-1 {
    position: absolute;
    top: 0;
    left: 75%;
    width: $margin;
    margin-left: -$margin + $margin/4;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-small) {
      width: $margin-small;
      margin-left: -$margin-small + $margin-small/4;
    }
  }

  .col-left-2 {
    position: absolute;
    top: 0;
    left: 37.5%;
    width: $margin;
    margin-left: -$margin/2;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-medium) {
      display: none;
    }
  }

  .col-right-2 {
    position: absolute;
    top: 0;
    left: 62.5%;
    width: $margin;
    margin-left: -$margin + $margin/2;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-medium) {
      display: none;
    }
  }

  .col-left-3 {
    position: absolute;
    top: 0;
    left: 12.5%;
    width: $margin;
    margin-left: -$margin/2;
    height: 100%;
    outline: 1px $guide-color solid;
    @media (max-width: $break-small) {
      display: none;
    }
  }
};