#contact {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  margin-top: $nav-height + $margin;
  box-sizing: border-box;
  border-left: $margin / 2 solid transparent;
  border-right: $margin / 2 solid transparent;
  @media (max-width: $break-medium) {
    margin-top: $nav-height + $margin *2;
  }
  @media (max-width: $break-small) {
    margin-top: $nav-height-small + $margin-small;
    border-left: $margin-small / 2 solid transparent;
    border-right: $margin-small / 2 solid transparent;
  }

  body[data-state='contact'] & {
    @include displayPage();
  }

  .ac {
    padding-top: $margin;
    text-align: center !important;
  }
}