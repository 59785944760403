@mixin backgroundRetinaImage($image) {
  @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url($image);
  }
}
@mixin backgroundImage($image, $width, $height) {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $width, $height;
  background-image: url($image);
}
@mixin centeredBackground() {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@mixin topLeftBackground($w,$h) {
  background-size: $w+px $h+px;
  background-repeat: no-repeat;
  background-position: top left;
}
@mixin topRightBackground($w,$h) {
  background-size: $w+px $h+px;
  background-repeat: no-repeat;
  background-position: top right;
}
@mixin bottomLeftBackground($w,$h) {
  background-size: $w+px $h+px;
  background-repeat: no-repeat;
  background-position: bottom left;
}
@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}
@mixin scale($scale) {
  @include transform(scale($scale));
}
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin transition($element, $time, $easing) {
  -webkit-transition: $element $time $easing;
  -moz-transition: $element $time $easing;
  -ms-transition: $element $time $easing;
  -o-transition: $element $time $easing;
  transition: $element $time $easing;
}
@mixin unSelectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  cursor: default;
}
@mixin roundedBox($rad) {
  -moz-border-top-left-radius: $rad;
  -moz-border-bottom-left-radius: $rad;
  -moz-border-top-right-radius: $rad;
  -moz-border-bottom-right-radius: $rad;
  -webkit-border-radius: $rad;
  border-radius: $rad;
}
@mixin wordwrap() {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}
@mixin reset3d() {
  -webkit-transform: none;
  /* Safari and Chrome */
  -moz-transform: none;
  /* Firefox */
  -ms-transform: none;
  /* IE 9 */
  -o-transform: none;
  /* Opera */
  transform: none;
}
@mixin navigationSelected() {
  .nav-button:before {
    top: 54%;
    margin-top: -10px;
    width: 32px;
    height: 8px;
    margin-left: -16px;
  }

  .nav-button {
    .knob:after {
      margin-top: 14px;
      width: 60px;
      margin-left: 0;
    }

    .knob {
      width: 60px;
      height: 2px;
      margin-left: -30px;
      top: 54%;
      height: 16px;
      margin-top: -8px;
    }
  }
}
@mixin displayPage() {
  transform: scale(1);
  opacity: 1;
  position: relative;
  visibility: visible;
}