.article {
  position: relative;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  margin-top: $margin;
  margin-bottom: $margin;
  display: flex;
  flex-wrap: wrap;
  float: left;
  text-align: center;
  @media (max-width: $break-small) {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    margin-top: $margin-small / 2;
    margin-bottom: $margin-small;
  }

  .al {
    display: inline-block;
    float: left;
    flex-grow: 1;
    width: 25%;
    text-align: justify;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      width: 100%;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }

    img {
      width: 100%;
    }
  }

  .ac {
    display: inline-block;
    float: left;
    flex-grow: 1;
    width: 50%;
    text-align: justify;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-medium) {
      width: 100%;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }
  }

  .ar {
    display: inline-block;
    float: left;
    flex-grow: 1;
    width: 25%;
    text-align: justify;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-small) {
      width: 100%;
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }

    img {
      width: 100%;
    }
  }
}