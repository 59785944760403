.case-left:hover,
.case-right:hover {
  h1 {
    color: $production-color;
  }

  .hl:after,
  .hr:after {
    background-color: $production-color;
  }

  .info {
    padding-bottom: $margin;
    opacity: 1;
    transition-delay: 0.2s;
    @media (max-width: $break-small) {
      padding-bottom: $margin-small;
    }
  }

  .image {
    .holder:before {
      opacity: 0.8;
    }
  }
}

.case-left,
.case-right {
  position: relative;
  width: 25%;
  box-sizing: border-box;
  border-left: $margin / 2 solid transparent;
  border-right: $margin / 2 solid transparent;
  margin-bottom: $margin * 3;
  cursor: pointer;
  color: $background-color;
  @media (max-width: $break-medium) {
    width: 50%;
    margin-bottom: $margin * 2;
  }
  @media (max-width: $break-small) {
    width: 100%;
    margin-bottom: $margin-small;
    border-left: $margin-small / 2 solid transparent;
    border-right: $margin-small / 2 solid transparent;
  }

  .info {
    position: absolute;
    text-align: left;
    bottom: 48px;
    padding-left: $margin;
    padding-bottom: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0s;
    @media (max-width: $break-small) {
      padding-left: $margin-small;
    }
  }

  .header {
    width: 100%;
    left: 0;
    @media (max-width: $break-medium) {
      width: 100%;
      left: 0;
    }

    h1 {
      font-size: 20px;
      @media (max-width: $break-small) {
        font-size: 18px;
      }
    }
  }

  .image {
    position: relative;
    width: 100%;
    left: 0;
    @media (max-width: $break-small) {
      width: 100%;
      left: 0;
    }

    .holder:before {
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background: -moz-linear-gradient(top, rgba(255,255,224,0) 0%, $production-color 50%);
      background: -webkit-linear-gradient(top, rgba(255,255,224,0) 0%, $production-color 50%);
      background: linear-gradient(to bottom, rgba(255,255,224,0) 0%, $production-color 50%);
      opacity: 0;
      transition: opacity 0.6s;
    }

    .holder {
      position: relative;
      width: 100%;
      height: 100%;
      background-color: #333;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: 1px #CCC solid;
    }

    img {
      width: 100%;
      opacity: 0;
    }
  }
}