/* */
$break-medium: 1300px;
$break-small: 800px;
/* */
$background-color: #FFFFE0;
/* */
$guide-color: #009900;
$guide-color-2: lighten(#363636, 5%);
$margin: 22px;
$margin-small: 10px;
$nav-height: 128px;
$nav-height-small: 60px;
$line-thick: 2px;
$line-color: #333;
/* */
$transition-speed1: 0.3s;
$transition-speed2: 0.5s;
$transition-speed3: 1s;
$transition-speed4: 2s;
$transition-delay: 1s;
/* */
$production-color: #cc6633;
$studio-color: #a82315;
$record-color: #56662e;
$contact-color: #3378e7;
/* */
$easeOutCubic: 0.215, 0.610, 0.355, 1.000;
$easeInCubic: 0.550, 0.055, 0.675, 0.190;
$easeInOutQuart: 0.770, 0.000, 0.175, 1.000;