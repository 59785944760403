@font-face {
  font-family: 'BebasNeueRegular';
  src: url("fonts/BebasNeue Regular.eot");
  src: url("fonts/BebasNeue Regular.eot?#iefix") format('embedded-opentype'), url("fonts/BebasNeue Regular.woff") format('woff'), url("fonts/BebasNeue Regular.ttf") format('truetype'), url("fonts/BebasNeue Regular.svg#BebasNeue Regular") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeueBold';
  src: url("fonts/BebasNeue Bold.eot");
  src: url("fonts/BebasNeue Bold.eot?#iefix") format('embedded-opentype'), url("fonts/BebasNeue Bold.woff") format('woff'), url("fonts/BebasNeue Bold.ttf") format('truetype'), url("fonts/BebasNeue Bold.svg#BebasNeue Bold") format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'BebasNeueBook';
  src: url("fonts/BebasNeue Book.eot");
  src: url("fonts/BebasNeue Book.eot?#iefix") format('embedded-opentype'), url("fonts/BebasNeue Book.woff") format('woff'), url("fonts/BebasNeue Book.ttf") format('truetype'), url("fonts/BebasNeue Book.svg#BebasNeue Book") format('svg');
  font-weight: normal;
  font-style: normal;
}