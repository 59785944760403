.article-image:hover {
  .info {
    padding-bottom: $margin / 2;
    opacity: 1;
    transition-delay: 0.2s;
    @media (max-width: $break-small) {
      padding-bottom: $margin-small;
    }
  }

  .holder:before {
    opacity: 0.8;
  }
}

.article-image {
  position: relative;
  left: 0;
  width: 50%;
  margin-top: $margin / 2;
  margin-bottom: $margin / 2;
  display: inline-block;
  float: left;
  text-align: center;
  box-sizing: border-box;
  border-left: $margin / 2 solid transparent;
  border-right: $margin / 2 solid transparent;
  @media (max-width: $break-medium) {
    width: 50%;
  }
  @media (max-width: $break-small) {
    border-left: $margin-small / 2 solid transparent;
    border-right: $margin-small / 2 solid transparent;
    width: 100%;
    margin-top: $margin-small / 2;
    margin-bottom: $margin-small / 2;
  }

  .holder {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  .holder:before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    background: -moz-linear-gradient(top, rgba(168,35,21,0) 50%, $studio-color 80%);
    background: -webkit-linear-gradient(top, rgba(168,35,21,0) 50%, $studio-color 80%);
    background: linear-gradient(to bottom, rgba(168,35,21,0) 50%, $studio-color 80%);
    opacity: 0;
    transition: opacity 0.6s;
  }

  .info {
    position: absolute;
    text-align: left;
    left: 0;
    bottom: 0;
    padding-left: $margin;
    padding-bottom: 0;
    width: 100%;
    pointer-events: none;
    opacity: 0;
    transition: all 0.3s;
    transition-delay: 0s;
    color: $background-color;
    transition: all 0.3s;
    transition-delay: 0s;
    @media (max-width: $break-small) {
      padding-left: $margin-small/2 + $margin-small;
    }
  }

  .copy {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    left: 25%;
    text-align: left;
    box-sizing: border-box;
    border-left: $margin / 2 solid transparent;
    border-right: $margin / 2 solid transparent;
    @media (max-width: $break-medium) {
      width: 100%;
      left: 0;
    }
    @media (max-width: $break-small) {
      border-left: $margin-small / 2 solid transparent;
      border-right: $margin-small / 2 solid transparent;
    }
  }
}